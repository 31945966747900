/* eslint-disable import/prefer-default-export */
export const optionsHandover = (t) => [
    {
        aclCode: 'handover_by_courier',
        key: 'handover',
        label: t('menu:Handover'),
        children: [
            {
                aclCode: 'handover_by_courier',
                key: 'createhandover',
                label: t('menu:CreateHandover'),
                url: '/handover/create',
            },
            {
                aclCode: 'handover_by_courier',
                key: 'sessionlist',
                label: t('menu:SessionList'),
                url: '/handover/sessionlist',
            },
        ],
    },
];
